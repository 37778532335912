









import {
  defineComponent,
  ref,
  onMounted,
  useAsync,
  computed
} from "@nuxtjs/composition-api"; 
import DYProduct from "~/components/FortyTwo/homepage/DYRecommendation/DYProduct.vue";
import { useStock } from '~/modules/catalog/stock/useStock';
import DYProductWithEmpty from "~/components/FortyTwo/homepage/DYRecommendation/DYProductWithEmpty.vue";
import { merge } from 'lodash-es';
import { useConfig } from "~/composables";

export default defineComponent({
  name: "DYDataHandler",
  components: {
    DYProduct,
    DYProductWithEmpty,

  },
  props: {
    data: {
      type: Object,
      default: {},
      required: true,
    },
  },
 
  setup(props) {
    const { getStocksBySku } = useStock();
    const dyitemsku:any = ref("");
    const dyitemtitle:any = ref("");
    const dyitemDecisId:any = ref("");
    const dyitemVariationId:any = ref("");
    const dyitemdata:any = ref([]);
    const dydata:any = ref([]);
    const { config } = useConfig();
    const baseMediaUrl = config.value.base_media_url;
   
    dyitemsku.value = props.data.itemsku;
    dyitemdata.value = props.data.data;
    dyitemtitle.value = props.data.title;
    dyitemDecisId.value = props.data.decisionId;
    dyitemVariationId.value = props.data.variationsId;
  
    useAsync(async () => {
        const fetchProductStockStatus  = await getStocksBySku(dyitemsku.value,50);
        dyitemdata.value.forEach((product, index)=>{
          const finalDataArray = fetchProductStockStatus.items.filter(databaseproduct => databaseproduct.stock_status === "IN_STOCK" && databaseproduct.sku == product.sku);
          if(finalDataArray.length > 0) {
            finalDataArray[0] = merge({}, finalDataArray[0], product);
            dydata.value.push(finalDataArray[0]);
          }

        });

        const currenturl =  window.location.origin+'/';
        const checkedcurrenturl = baseMediaUrl.replace('media/','');
        
        dydata.value.forEach((product, index)=>{
          const getimagelink =  product.image;
          let url = new URL(getimagelink);
          const pathimagename = url.pathname.slice(1);
          // const getimagepath = getimagelink.replace(checkedcurrenturl,'');
          const encodedimagepath = window.btoa(pathimagename);
          const imagepathlink = checkedcurrenturl+'media/resize/catalog/product/'+encodedimagepath+'/?s=thumbnail';
          product.image = imagepathlink;
        }) 
    });
    return {
      dydata,
      dyitemtitle,
      dyitemDecisId,
      dyitemVariationId,
      isDYproductready:computed(()=> dydata.value ? true : false),
    };
  },
});
















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
// import { Product } from '~/modules/catalog/product/types';
// import { getPrice as getProductPrice } from '~/modules/catalog/product/getters/productGetters';
// import { getConfigurableProductPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductPriceCommand';
// import { getConfigurableProductSpecialPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductSpecialPriceCommand';

export default defineComponent({
  name: 'FortyTwoDYPrice',
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const productPrice = computed(() => getProductPrice(props.product.price_range).regular);
    const productSpecialPrice = computed(() => (getProductPrice(props.product.price_range).special && getProductPrice(props.product.price_range).special));
    const productCrossedOutPrice = computed(() => props.product?.crossed_out_list_price ?? 0);
    const hideCrossOutPrice = computed(() => props.product?.crossed_out_list_price ? props.product?.crossed_out_list_price <= getProductPrice(props.product.price_range).regular : true );

    const getProductPrice = (price_range) => {
        let regular = 0;
        let special = null;
        let maximum = null;
        let final = null;
        if (price_range) {
            regular = price_range.minimum_price.regular_price.value;
            final = price_range.minimum_price.final_price.value;
            maximum = price_range.maximum_price.final_price.value;

            if (final < regular) {
            special = final;
            }
        }

        return {
            regular,
            special,
            maximum,
            final,
        };
    };

    return {
        productPrice,
        productSpecialPrice,
        productCrossedOutPrice,
        hideCrossOutPrice,
    }
  }
});

import { useApi } from '~/composables/useApi';
import getStockQuery from '~/modules/catalog/stock/getStockQuery.gql';
import { ProductStockStatus } from '~/modules/GraphQL/types';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';

export interface StockItem {
  stock_status: ProductStockStatus;
  sku: String;
}

export interface StockItems {
  items: StockItem[]
}

export const useStock= () => {
  const getStocks = async (variables: GetProductSearchParams): Promise<StockItems> => {
    const { query } = useApi();
    const { data } = await query<{ products: StockItems }>(getStockQuery, variables);

    return data?.products ?? { items: [] };
  };

  const getStocksBySku = (skus: string[], pageSize = 50, currentPage = 1): Promise<StockItems> => getStocks(
    { filter: { sku: { in: skus } }, pageSize, currentPage },
  );

  return { getStocksBySku, getStocks };
};

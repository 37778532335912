














import {
  defineComponent,
} from "@nuxtjs/composition-api";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

export default defineComponent({
  name: "DYProductWithEmpty",
  components: {
      SkeletonLoader,
  },

});
